<template>
    <div class="py-16 px-2">
        <h1 class="text-red text-lg mb-6">
            Create Tag
        </h1>
        <div class="" >
            <FormTemplate @response="formResponse" button="Create" method="post" action="/tag/create" :formdata="formData">
                <TextInput type="text" label="Name" v-model="item.name" :required="false" ></TextInput>
            </FormTemplate>
        </div>
    </div>
</template>

<script>
import Create from '../../../mixins/Create'

export default {
    name: 'CreateTag',
    mixins: [Create],
    data: () => ({
        back: '/tags',
        action: '/tag/',
        item: {
            name: '',
        },
    }),
    computed: {
        formData() {
            return {
                'tag':this.item,
            }
        },
    },
    props: [],
    methods: {
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
