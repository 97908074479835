<template>
<div class="container py-16 px-2">
    <div class="flex">
        <h1 class="text-red text-lg mb-6">
            Tags
        </h1>
        <LinkTemplate :to="{path:'/tags/create'}" class="my-6 ml-6">
            Add New Tag
        </LinkTemplate>
    </div>
    <div class="">
        <TextInput @input="maybeSearch" type="text" label="Search" v-model="search" :required="false" ></TextInput>
    </div>
    <div v-if="items.length || search != ''"  class="">
        <TableTemplate v-if="loading == false" :candelete="false" canview="/tags/" :columns="columns" :items="items"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
    </div>
    <div  v-else  class="">
        <TableTemplate v-if="loading == false"  @item:deleted="ItemDeleted" :candelete="true" canview="/tags/" :columns="columns" :items="pages[page]"></TableTemplate>
        <img v-else class="m-auto my-4" style="width:50px;height:50px;" src="/assets/loading.gif" alt="Loading search submission">
        <Pagination @navigate="navigate" :count="count" :limit="limit" :page="page"></Pagination>
    </div>
</div>
</template>

<script>
import List from '../../../mixins/List'

export default {
    name: 'TagsList',
    mixins: [List],
    data: () => ({
        search: '',
        limit: 10,
        page: 0,
        action: '/tags',
        searchParams: {
            'model': 'Tag',
            'fields': ['id','name'],
            'strict' : false,
        },
        columns:[
            {
                id:'id',
                label:'ID',
            },
            {
                id:'name',
                label:'Name',
            }
        ],
    }),
    computed: {
    },
    props: [],
    methods: {
        navigate(index) {
            if(index > -1 && index <= Math.floor(this.count/10)) {
                this.page = index
                this.getItems()
            }
        },
    },
    watch: {
    },
    created() {
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
